import styled from 'styled-components';

export const BumpOfferWrapper = styled.div`
  width: 100%;
  margin: 0;
  cursor: pointer;

  &.rift {
    & > div {
      border-radius: 4px;
      border: 1px solid #ccd2e3;

      &.active {
        border: 1px solid #ad3b42;
        box-shadow: 0px 0px 6px #0000001a;
      }
    }

    & .content:after {
      border: 1px solid #001c72;
    }

    & .label input:checked + div:after {
      background-color: #001c72;
    }
  }

  &.compact {
    & > div {
      border-radius: 4px;
      border: 1px solid #ccd2e3;
      padding: 10px;

      &.active {
        border: 1px solid #ad3b42;
        box-shadow: 0 0 6px #0000001a;
      }
    }

    & .content:after {
      border: 1px solid #001c72;
    }

    & .label input:checked + div:after {
      background-color: #001c72;
    }
  }

  &.pdp2023 {
    & > div {
      border: 1px solid rgba(153, 164, 199, 0.8);
      border-radius: 8px;
      background: #f2f4f8;
      box-shadow: none;
    }
    & .content:after {
      border: 2px solid #001c72;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      right: 16px;
      left: unset !important;
      top: calc(50% - 12px);
      background: #fff;
    }
    & .label input:checked + div:before {
      top: calc(50% - 11px);
      left: unset !important;
      right: 30px;
    }
    & .label input:checked + div:after {
      background-color: #001c72;
    }
    & .content.active:before {
      left: unset !important;
      right: 30px;
      top: calc(50% - 11px);
    }
    & .content.active:after {
      background: #001c72;
    }
  }

  &.top-checkbox {
    & > div {
      padding: 25px;
    }
    & .content:after {
      width: 16px;
      height: 16px;
      right: 0;
      top: 3px;
      margin: 0;
      left: 0 !important;
    }
    & .content.active:before {
      top: 0;
      left: 0 !important;
    }
  }

  &.hide-checkbox {
    & .content:after {
      display: none;
    }
    & .content:before {
      display: none;
    }
  }

  &.blue {
    & > div {
      border-radius: 8px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      border: 1px solid #ccd2e3;
      background: #fff;
    }
    & .content {
      &:after {
        width: 20px;
        height: 20px;
        border-radius: 0;
        top: 16px;
        left: 16px !important;
      }
      &.active:after {
        background-color: none;
        border: 1px solid transparent;
      }
      &.active:before {
        top: 16px;
        left: 16px !important;
        background-image: url(/images/blue-tick.svg);
        width: 20px;
        height: 20px;
        transform: none;
        border: none;
      }
    }
  }
`;

export const BumpOffer = styled.div`
  padding: 10px 15px;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: relative;

  &.active {
    border: 1px solid #e26447;
    -webkit-box-shadow: 0 0 5px 0 #e26447;
    box-shadow: 0 0 5px 0 #e26447;
  }

  ${({ hideRibbon }) =>
    hideRibbon
      ? `
  & .content .content-text {
    padding-right: 0px;
  }
  `
      : ``}
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                border: 1px solid #E6E8F0;
                box-shadow: 0px 0px 6px #0000001A;
                padding: 20px 20px;

                &.active {
                    border: 1px solid #AD3B42;
                    -webkit-box-shadow: 0 0 5px 0 #AD3B42;
                    box-shadow: 0 0 5px 0 #AD3B42;
                }
                `;
      default:
        return;
    }
  }}
  &:after {
    content: ' ';
    background-repeat: no-repeat;
    display: block;
    width: 51px;
    height: 56px;
    position: absolute;
    top: -8px;
    right: -10px;

    @media (max-width: 500px) {
      background-size: 90%;
      width: 41px;
      height: 45px;
    }

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
             width:52px;
             height: 66px;
             background-image: url("/images/downsell-ribbon2.svg");
             background-size: cover;
             top: -8px;
             right: -15px;

             @media (max-width: 500px) {
                background-size: 100%;
                width:52px;
                height: 66px;
                right: -5px;
             }

             `;
      } else {
        return `
             background-image: url("/images/downsell-ribbon.svg");
              `;
      }
    }}

    ${({ hideRibbon }) => {
      if (hideRibbon) {
        return `
                    display: none;
                `;
      }
    }}
  }

  &.active:after {
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  ${({ hasChildren }) => {
    if (hasChildren) {
      return `
                padding: 0;
                & .content {
                    padding: 0;

                    &:before {
                        left: 20px!important;
                    }
                    &:after {
                        left: 21px!important;
                    }
                    & > div {
                        width: 100%;
                    }
                }
                & label > input:checked+div:before {
                    left: 20px;
                }
                & label > input:checked+div:after {
                    left: 21px;
                }
            `;
    } else {
      return ``;
    }
  }}
`;

export const Heading = styled.div`
  font-style: italic;
  font-family: Utopia Std, sans-serif;
  margin-bottom: 5px;
`;

export const BumpOfferLabel = styled.label`
  position: relative;

  & input:checked + div:before {
    position: absolute;
    content: '';
    top: calc(50% - 10px);
    left: -1px;
    width: 8px;
    height: 18px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index: 1;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  & input:checked + div:after {
    position: absolute;
    content: '';
    top: calc(50% - 9px);
    left: 0;
    border: 2px solid #d26546;
    background-color: #d26546;
    z-index: 0;
    -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
             border: 2px solid #BC2E3E;
              background-color: #BC2E3E;
              `;
      }
    }}
  }
`;

export const BumpOfferImage = styled.div`
  margin: auto;

  & > picture > img {
    max-width: 56px !important;

    @media (max-width: 375px) {
      max-width: 60px !important;
      margin-top: 20px;
    }
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                & > picture > img {
                    @media (max-width: 375px) {
                        margin-top: 0px;
                    }
                }
                `;
      default:
        return ``;
    }
  }}
`;

export const BumpOfferCheckbox = styled.input`
  display: none;
`;

export const BumpOfferContent = styled.div`
  display: flex;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;

  &.active:before {
    position: absolute;
    content: '';
    top: calc(50% - 10px);
    left: -1px;
    width: 8px;
    height: 18px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index: 1;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  &.active:after {
    position: absolute;
    content: '';
    top: calc(50% - 9px);
    left: 0;
    border: 2px solid #d26546;
    background-color: #d26546;
    z-index: 0;
    -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
             border: 2px solid #BC2E3E;
              background-color: #BC2E3E;
              `;
      }
    }}
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                padding-left: 25px;
                `;
      default:
        return ``;
    }
  }}
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: calc(50% - 9px);
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  &:after {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #5a5a5a;
    top: calc(50% - 9px);
    left: 0;
    z-index: 0;

    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                    border: 1px solid #CCD2E3;
                    border-radius: 50%;
                    
                    `;
        default:
          return ``;
      }
    }}
  }
`;

export const BumpOfferContentText = styled.div`
  font-size: 14px;
  margin: 5px 0 0 0;
  line-height: 1.6;
  padding-right: 30px;
  text-align: center;
  padding-left: 15px;

  & > span {
    color: #e26447;
    position: relative;
  }

  @media (max-width: 425px) {
    padding-right: 25px;
    padding-left: 15px;
    font-size: 14px;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                text-align: left;
                font-size: 16px;
                & > span {
                    color: #BC2E3E;
                }

                `;
      default:
        return;
    }
  }};

  @media (max-width: 500px) {
    & > span {
      display: inline;
    }
  }
`;

export const GreenBadge = styled.div`
  position: absolute;
  color: #fff;
  background-color: #22a594;
  padding: 2px 8px 0;
  border-radius: 24px;
  width: max-content;
  right: -25px;
  top: 28px;

  &:before {
    content: ' ';
    display: block;
    width: 1px;
    height: 1px;
    position: absolute;
    top: -10px;
    right: calc(50% - 10px);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #22a594;
  }
`;
