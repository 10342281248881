import React, { useContext } from 'react';
import * as S from './BumpOfferSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';

const BumpOfferSelector = props => {
  const {
    bumpOfferIndex = 0,
    hideBumpOfferDiscount,
    bumpOfferPrefixText,
    hideRibbon,
    heading,
    style = `default`,
    showGreenBadge = false,
    greenBadgeText = `Lowest available price!`,
    hideCheckbox = false,
    children
  } = props;

  const context = useContext(FunnelContext);

  const {
    currency,
    onetime,
    subscription,
    currentCategory,
    pageDesign,
    bumpOfferChecked,
    setBumpOfferChecked,
    setBumpOfferIndex
  } = context;

  const currencySymbol = currency?.symbol || '$';

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  const handleBumpOffer = () => {
    setBumpOfferChecked(!bumpOfferChecked);
    setBumpOfferIndex(bumpOfferIndex || 0);
  };

  const BumpOfferDiscount = ({ discount_percentage, children }) => {
    return hideBumpOfferDiscount ? (
      ``
    ) : (
      <span>
        ({discount_percentage}% Off){children}
      </span>
    );
  };

  const prefix_text =
    bumpOfferPrefixText && bumpOfferPrefixText !== ``
      ? bumpOfferPrefixText
      : `Enhance Your Chews With Our`;

  const { checkoutData, discount_percentage, display_title } = bumpoffers[
    bumpOfferIndex || 0
  ];

  return (
    <S.BumpOfferWrapper
      pageDesign={pageDesign}
      className={`${style} ${hideCheckbox ? `hide-checkbox` : ``}`}
      onClick={handleBumpOffer}
    >
      <S.BumpOffer
        hasChildren={children}
        hideRibbon={hideRibbon}
        className={bumpOfferChecked ? 'active' : ''}
        pageDesign={pageDesign}
      >
        <S.BumpOfferLabel pageDesign={pageDesign} className="label">
          {heading && <S.Heading>{heading}</S.Heading>}
          {hideCheckbox ? (
            <S.BumpOfferCheckbox
              type="checkbox"
              value={bumpOfferIndex}
              className="checkbox"
              checked={bumpOfferChecked}
              onChange={handleBumpOffer}
            />
          ) : null}
          <S.BumpOfferContent
            pageDesign={pageDesign}
            className={`content ${bumpOfferChecked ? 'active' : ''}`}
          >
            {children && children}
            {!children && (
              <>
                <S.BumpOfferImage pageDesign={pageDesign}>
                  <img
                    alt="bumpoffer product"
                    loading="lazy"
                    src={checkoutData.image}
                    width={56}
                    height={56}
                  />
                </S.BumpOfferImage>
                <S.BumpOfferContentText
                  pageDesign={pageDesign}
                  className="content-text"
                >
                  {prefix_text} {display_title} for {currencySymbol}
                  {checkoutData.discounted_price.toFixed(2)}{' '}
                  <BumpOfferDiscount discount_percentage={discount_percentage}>
                    {showGreenBadge && (
                      <S.GreenBadge>{greenBadgeText}</S.GreenBadge>
                    )}
                  </BumpOfferDiscount>
                </S.BumpOfferContentText>
              </>
            )}
          </S.BumpOfferContent>
        </S.BumpOfferLabel>
      </S.BumpOffer>
    </S.BumpOfferWrapper>
  );
};

export default BumpOfferSelector;
